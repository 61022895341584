import { Route, Routes, Navigate } from "react-router-dom";
import { AuthData } from "./index";
import { nav } from "./navigation";


export const RenderRoutes = () => {
     const { isAuthenticated } = AuthData();
     return (
          <Routes>
               {nav.map((r, i) => {

                    if (r.isPrivate) {
                         if (isAuthenticated) {
                              return <Route key={i} path={r.path} element={r.element} />
                         }
                    } else {
                         return <Route key={i} path={r.path} element={r.element} />
                    }
                    return null
               })}
               <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
     )
}
