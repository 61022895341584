
import React from "react";
import { Header } from "antd/es/layout/layout";
import { Input, Button, Dropdown, Avatar } from "antd";
import { SearchOutlined, LoginOutlined, SettingOutlined } from "@ant-design/icons";
import { CustomImg } from 'utils/images';
import { AuthData } from "Layout";
import { useNavigate } from "react-router-dom";
import api from "api"

import { useUtils } from 'hooks/useUtils';

import styles from "./style.module.scss";



export const RenderHeader = () => {
  const { t } = useUtils();
  const navigate = useNavigate();
  const { user, isAuthenticated, loginWithRedirect, logoutWithRedirect, getAccessTokenSilently } = AuthData();

  const setToken = async () => {
    let token = await getAccessTokenSilently();
    localStorage.setItem("AccessToken", token)
    try {
        const response = await api.user.getProfileLocale();
        if (response.status === "success") {
          localStorage.setItem("locale", response.data.usuario.locale)
        }
    } catch (error) {
      console.log(error)
    }


  }
  if (isAuthenticated) {
    setToken()
  } else {
    // loginWithRedirect()
  }


  const items = [
    {
      key: '1',
      label: (
        <>Registrado como <strong> {user && user.name}</strong> </>
      ),
    },
    {
      key: '2',
      label: (
        <><SettingOutlined /> Configuración</>
      ),
      onClick: () => { navigate("/profile"); }
    },
    {
      key: '3',
      label: (
        <><LoginOutlined /> Salir</>
      ),
      onClick: () => logoutWithRedirect(),
    },
  ];


  return (
    <Header className={styles.topHeader}>
      <div className={styles.header}>
        <a href="/"><img src={CustomImg.Logo} alt="img" width={35} height={33} style={{verticalAlign:'middle'}}/></a>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Buscar"
          className={styles.inputField}
        />
      </div>
      {!isAuthenticated && (
        <Button
          id="qsLoginBtn"
          color="primary"
          className="btn-margin"
          onClick={() => loginWithRedirect()}
        >
          {t("login")}
        </Button>
      )}
      {isAuthenticated && (
        <Dropdown className="list-inline-item" menu={{ items }}>
          <Avatar size={64} src={user.picture || ''} alt={user && user.name}></Avatar>
        </Dropdown>
      )}
    </Header>
  );
};



