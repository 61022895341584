import React from "react";
import { Button, Col, Row, Typography,Card } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { Content } from "antd/es/layout/layout";
import { useUtils } from 'hooks/useUtils';
//Loties
import Lottie from "react-lottie-player";
import L0 from 'assets/lotties/mujersentadaenmonedas.json';
import L1 from 'assets/lotties/seleccionaropciones.json';
import L2 from 'assets/lotties/tabletgraficos.json';


//Styles
import styles from "views/style.module.scss";
import '../style.module.scss';

const { Text, Title } = Typography;

export const Financieras = () => {
    const { t } = useUtils();
    const rowgutter = [16, 24];
    const rowStyle = { marginTop: '20px', marginBottom: '20px' };
    return (
        <Content>
            <div className={styles.contentSectionWrapper}>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("Financial.title")}</Title>
                        <Title level={1}>{t("Financial.title1")}</Title>
                        <Text>{t("Financial.text")}</Text>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L0} />
                    </Col>
                </Row>

                <Row gutter={rowgutter} style={rowStyle}>
                    <Col offset={2} span={20}>
                        <Title className="center" level={2}>{t("Financial.title2")}</Title>
                        <Text className="center">{t("Financial.text1")}</Text>
                    </Col>
                    <Col span={2}></Col>

                </Row>
                <br /><br />
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Lottie loop play animationData={L1} />
                    </Col>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("Financial.title3")}</Title>
                        <Title level={1}>{t("Financial.title4")}</Title>
                        <Text>{t("Financial.text2")}</Text>
                    </Col>

                </Row>

                <br /><br />

                <Row>
                    <Col span={8}>
                        <Card
                            hoverable
                            style={{ width: '90%', height: '300px', backgroundColor: '#d3bcf6' }}
                        >
                            <img className="dash-destinos" src="/assets/imgs/network.png" alt="network"/>
                            <Title level={2} style={{ color: 'white' }}>{t("Financial.card1")}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            hoverable
                            style={{ width: '90%', height: '300px', backgroundColor: '#add5fa' }}
                        >
                            <img className="dash-destinos" src="/assets/imgs/reporting.png" alt="reporting"/>
                            <Title level={2} style={{ color: 'white' }}>{t("Financial.card2")}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            hoverable
                            style={{ width: '90%', height: '300px', backgroundColor: '#9bd3ae', alignContent: 'flex-end' }}
                        >
                            <img className="dash-destinos" src="/assets/imgs/fuentesexternas.png" alt="fuentesexternas"/>
                            <Title level={2} style={{ color: 'white' }}>{t("Financial.card3")}</Title>
                        </Card>
                    </Col>
                </Row>

                <br /><br />
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("Financial.title5")}</Title>
                        <Title level={1}>{t("Financial.title6")}</Title>
                        <Text>{t("Financial.text3")}</Text>
                        <br /><br />
                        <Text>{t("Financial.text4")}</Text>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L2} />
                    </Col>
                </Row>
                <br />
                <br />

                <Row>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("Financial.title6")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check1")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check2")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check3")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("Financial.title7")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check4")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check5")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check6")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("Financial.title8")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check7")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check8")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("Financial.check9")}</Title>
                    </Col>
                </Row>

                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={24}>
                        <Title className="center" level={1}>{t("Financial.title10")}</Title>
                    </Col>
                    <Col span={24} style={{ marginTop: '-50px' }}>
                        <Title className="center" level={1}>{t("ipcallgotelohacefacil")}</Title>
                    </Col>
                    <Col offset={8} span={8}>
                        <Button
                            className="btn-naranja center"
                            shape="round"
                            size="large"
                            onClick={() => window.open("/contacto", "_self")}>{t("btn.pedirinfo")}</Button>
                    </Col>
                </Row>
            </div>
        </Content>
    );
};