import esLang from './entries/es_AR';
import enLang from './entries/en_US';
import zhLang from './entries/zh_CN';
import frLang from './entries/fr_FR';
import jaLang from './entries/ja_JP'
import { THEME_CONFIG } from 'config/UserConfig';
import moment from 'moment';
import 'moment/locale/es';

const AppLocale = {
    es: esLang,
    en: enLang,
    zh: zhLang,
    fr: frLang,
    ja: jaLang
};

moment.locale(THEME_CONFIG.locale)

export default AppLocale;