
import { ProfileComponent } from "views/profile";
import { ContentSection } from "views/dashboard";
import { Caracteristicas } from "views/caracteristicas";
import { Salud } from "views/healthcare";
import { Financieras } from 'views/financial';
import { Bpo } from 'views/bpo';


export const nav = [
     //PUBLIC
     { path: "/", name: "Home", element: <ContentSection />, isMenu: false, isPrivate: false },
     { path: "/crm-features", name: "Features", element: <Caracteristicas />, isMenu: false, isPrivate: false },
     { path: "/healthcare", name: "Healthcare", element: <Salud />, isMenu: false, isPrivate: false },
     { path: "/financial", name: "Financial", element: <Financieras />, isMenu: false, isPrivate: false },
     { path: "/bpo-contact-center", name: "BpoContactCenter", element: <Bpo />, isMenu: false, isPrivate: false },
     //PRIVATE
     { path: "/profile", name: "Profile", element: <ProfileComponent />, isMenu: false, isPrivate: true },
]