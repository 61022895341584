import React, { useState, useEffect } from 'react';
import { Content, } from "antd/es/layout/layout";
import { Row, Col, Card, Divider, Input, Form, Select, Alert, Space, Button } from "antd";
import styles from "views/style.module.scss";
import Loading from "components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import api from "api"
import { useUtils, Refresh } from 'hooks/useUtils';
import { THEME_CONFIG } from 'config/UserConfig';


export const ProfileComponent = () => {

  const [form] = Form.useForm();
  const { user } = useAuth0();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(null);
  const { t } = useUtils()
  const { i18n } = Refresh()


  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await api.user.profile();
        if (response.status === "success") {
          const result = await response.data.cuenta;
          setProfile(result);
        }
      } catch (error) {
        setError('Error de red');
        console.log(error)
      }
    };
    fetchData(); // Llamar a la función para obtener los datos cuando el componente se monte
  }, []);

  const saveData = async (values) => {
    try {
      const response = await api.user.setProfile(values);
      if (response.status === "success") {
        const result = await response.data.cuenta;
        setProfile(result);
      }

    } catch (error) {
      setError('Error de red ' + error.error());
      console.log(error)
    }
  }
  const saveLang = async (values) => {
    try {
      const response = await api.user.setProfileLocale(values);
      if (response.status === "success") {
        localStorage.setItem("locale", values)
        i18n(values)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onLang = (values) => {
    saveLang({ ...user, locale: values })
  };
  const onFinish = (values) => {
    saveData(values)
    setEdit(false)
  };
  const onFinishFailed = (errorInfo) => {
    setEdit(false)
  };
  return (
    <Content>
      <div className={styles.contentSectionWrapper}>
        <Card className={styles.mainCard}>
          <Row gutter={10} >
            <Col md={3}>
              <img
                src={user.picture}
                alt={t("Perfil")}
                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
              />
            </Col>
            <Col>
              <h2>{user.name}</h2>
              <p className="lead text-muted">{user.email}</p>
            </Col>
          </Row>
        </Card>

        <Card className={styles.mainCard} title={t("Datos de la Cuenta")} extra={
          <>
            {!edit && !error &&
              <Button type="link" onClick={() => { setEdit(true) }}>{t("editar")}</Button>
            }
            {edit && !error &&
              <>
                <Button type="link" onClick={() => { form.submit() }}>{t("guardar")}</Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => { form.resetFields(); setEdit(false) }}>{t("cancelar")}</Button>
              </>
            }
          </>
        }
          actions=
          {error &&
            [<Space direction="vertical" style={{ width: '100%' }}>
              <Alert type="error" message={error} banner />
            </Space>]
          }
        >

          <Form name="basic" layout={"vertical"} form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={24}   >
              <Col md={6} xs={24}>
                <h3>{t("Pais")}</h3>
                {edit &&
                  <Form.Item name="pais" initialValue={profile ? profile.pais : ""}>
                    <Input maxLength={50} placeholder={t("Pais")} />
                  </Form.Item>
                }
                {!edit &&
                  <p>{profile ? profile.pais : ""}</p>
                }
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col md={12} xs={24}>
                <h3>{t("empresa")}</h3>
                <p>
                  {edit &&
                    <Form.Item name="razonsocial" initialValue={profile ? profile.razonsocial : ""} >
                      <Input maxLength={100} placeholder={t("Nombre de la empresa")} />
                    </Form.Item>
                  }
                  {!edit &&
                    profile ? profile.razonsocial : ""
                  }
                </p>
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col md={24} xs={24}>
                {edit &&
                  <>
                    <Row gutter={24}>
                      <Col md={6} xs={24}>
                        <h3>{t("CUIT")}</h3>
                        <Form.Item name="cuit" initialValue={profile ? profile.cuit : ""}>
                          <Input maxLength={13} placeholder={t("CUIT")} />
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <h3>{t("Posicion Fiscal")}</h3>
                        <Form.Item name="pfiscal" initialValue={profile ? profile.pfiscal : ""}>
                          <Input maxLength={50} placeholder={t("Posicion Fiscal")} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>}
                {!edit &&
                  <>
                    <h3>{t("CUIT")}</h3>
                    <p>{profile ? profile.cuit : ""}</p>
                    <p>{profile ? profile.pfiscal : ""}</p>
                  </>
                }
              </Col>
            </Row>
            <Divider />
            <Row >
              <Col md={6} xs={24}>
                <h3>{t("Telefono")}</h3>
                <p>
                  {edit &&
                    <Form.Item name="telefono" initialValue={profile ? profile.telefono : ""}>
                      <Input maxLength={20} placeholder={t("Telefono")} />
                    </Form.Item>
                  }
                  {!edit &&
                    profile ? profile.telefono : ""
                  }
                </p>
              </Col>
            </Row>
            <Divider />
            <Row >
              <Col md={24} xs={24}>
                <h3>{t("Domicilio")}</h3>
                {edit &&
                  <>
                    <Row gutter={24}>
                      <Col md={24} xs={24}>
                        <Form.Item name="domicilio" initialValue={profile ? profile.domicilio : ""}>
                          <Input maxLength={200} placeholder={t("ingrese calle numero piso depto.")} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col md={4} xs={24}>
                        <h3>{t("CodigoPostal")}</h3>
                        <Form.Item name="cp" initialValue={profile ? profile.cp : ""}>
                          <Input maxLength={10} placeholder={t("CodigoPostal")} />
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <h3> {t("Ciudad")}</h3>
                        <Form.Item name="ciudad" initialValue={profile ? profile.ciudad : ""}>
                          <Input maxLength={20} placeholder={t("Ciudad")} />
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <h3>{t("Provincia")}</h3>
                        <Form.Item name="provincia" initialValue={profile ? profile.provincia : ""}>
                          <Input maxLength={50} placeholder={t("Provincia")} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                }
                {!edit &&
                  <>
                    <p>{profile ? profile.domicilio : ""}</p>
                    <p>{profile ? t("CodigoPostal") + " " + profile.cp : ""} {profile ? " - " + profile.ciudad : ""} {profile ? " - " + profile.provincia : ""}</p>
                  </>
                }

              </Col>
            </Row>
          </Form>
        </Card>
        <Card className={styles.mainCard} title={t("Preferencias Generales")} >
          <Row gutter={24}   >
            <Col md={6} xs={24}>
              <h3>{t("Idioma")}</h3>
              <Select
                defaultValue={THEME_CONFIG.locale}
                style={{ width: 120 }}
                onChange={onLang}
                options={[
                  { value: 'es', label: t("Español") },
                  { value: 'en', label: t("English") },
                ]}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </Content>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
