
import React from "react";
import { Content, Footer } from "antd/es/layout/layout";
import { Col, Row, Typography } from "antd";
import { CustomImg } from "utils/images";
import { useUtils } from 'hooks/useUtils';
import { FULL_YEAR } from "utils/constants";

// import styles from "./style.module.scss";

const { Text, Title } = Typography;
export const socialMedia = [
  { source: CustomImg.FacebookIcon, alt: "facebook", link: "https://www.facebook.com/ip.callGO/" },
  { source: CustomImg.InstagramIcon, alt: "Instagram", link: "https://www.instagram.com/ip.callgo/" },
  { source: CustomImg.GithubIcon, alt: "Mail", link: "mailto:ventas@ipcallgo.com" },
  { source: CustomImg.Wsp, alt: "WhatsApp", link: "https://api.whatsapp.com/send?phone=5491121501506&text=Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20su%20CRM." },
]

export const RenderFooter = () => {
  const { t } = useUtils();
  const footerLinks = [
    { tipo: "ipgo", source: t("QuienesSomos"), link: "" },
    { tipo: "ipgo", source: t("Slogan"), link: "" },
    { tipo: "sol", source: t("CentroContactosBPO"), link: "/cco" },
    { tipo: "sol", source: t("CuidadoSalud"), link: "/cds" },
    { tipo: "sol", source: t("ServiciosFinancieros"), link: "/sfi" },
    { tipo: "sol", source: t("Pymes"), link: "/pymes" },
    { tipo: "ftr", source: t("GestionInterna"), link: "/gesin" },
    { tipo: "ftr", source: t("Centralita"), link: "/centra" },
    { tipo: "ftr", source: t("PlanificacionEstrategias"), link: "/plestr" },
    { tipo: "ftr", source: t("Faq"), link: "/faqs" },
    { tipo: "nos", source: t("Contacto"), link: "/XA4" },
    { tipo: "nos", source: t("Blog"), link: "/XA4" },
    { tipo: "usr", source: t("TermCondic"), link: "/terms-conditions" },
    { tipo: "usr", source: t("Politica"), link: "/privacy-policy" },
  ]
  return (
    <Content>
      <Footer className={"fullfooter"}>
        <Row>
          <Col span={9}>
            <img src="/img/logo.png" alt="ipcall-logo" />
            {footerLinks.filter(elm => elm.tipo === "ipgo").map((data, ind) => {
              return <Row key={ind}>
                <Text key={ind}>
                  {data.source}
                </Text>
              </Row>;
            })}
          </Col>
          <Col span={5}>
            <Title level={3}>{t("Soluciones")}</Title>
            {footerLinks.filter(elm => elm.tipo === "sol").map((data, ind) => {
              return <Row key={ind}>
                <Text key={ind} style={{ cursor: 'pointer' }}
                  onClick={() => window.open(data.link, '_self')}>
                  {data.source}
                </Text>
              </Row>;
            })}
          </Col>
          <Col span={5}>
            <Title level={3}>{t("Features")}</Title>
            {footerLinks.filter(elm => elm.tipo === "ftr").map((data, ind) => {
              return <Row key={ind}>
                <Text key={ind} style={{ cursor: 'pointer' }}
                  onClick={() => window.open(data.link, '_self')}>
                  {data.source}
                </Text>
              </Row>;
            })}

          </Col>
          <Col span={5}>
            <Title level={3}>{t("Nosotros")}</Title>
            {footerLinks.filter(elm => elm.tipo === "nos").map((data, ind) => {
              return <Row key={ind}>
                <Text key={ind} style={{ cursor: 'pointer' }}
                  onClick={() => window.open(data.link, '_self')}>
                  {data.source}
                </Text>
              </Row>;
            })}
            <Title level={3}>{t("Usuario")}</Title>
            {footerLinks.filter(elm => elm.tipo === "usr").map((data, ind) => {
              return <Row key={ind}>
                <Text key={ind} style={{ cursor: 'pointer' }}
                  onClick={() => window.open(data.link, '_self')}>
                  {data.source}
                </Text>
              </Row>;
            })}
          </Col>
        </Row>
        <br />
        <Row>
          <Text>{t("Creado por")} Ip.Sistemas {FULL_YEAR}</Text>
          <div className="derecha">
            {socialMedia.map((social, ind) => {
              return <img key={ind} src={social.source} alt={social.alt} onClick={() => window.open(social.link)} style={{ cursor: 'pointer', margin: '0px 5px' }} />;
            })}
          </div>
        </Row>
      </Footer>
    </Content>
  );
};



