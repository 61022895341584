import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { Content } from "antd/es/layout/layout";
import { useUtils } from 'hooks/useUtils';
//Loties
import Lottie from "react-lottie-player";
import L0 from 'assets/lotties/celdoctor.json';
import L1 from 'assets/lotties/smsflotante.json';
import L2 from 'assets/lotties/doctoraenpantalla.json';
import L3 from 'assets/lotties/enfermera.json';

//Styles
import styles from "views/style.module.scss";
import '../style.module.scss';

const { Text, Title } = Typography;

export const Salud = () => {
    const { t } = useUtils();
    const rowgutter = [16, 24];
    const rowStyle = { marginTop: '20px', marginBottom: '20px' };
    return (
        <Content>
            <div className={styles.contentSectionWrapper}>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("HealthCare.title")}</Title>
                        <Title level={1}>{t("HealthCare.title1")}</Title>
                        <Text>{t("HealthCare.text")}</Text>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L0} />
                    </Col>
                </Row>

                <Row gutter={rowgutter} style={rowStyle}>
                    <Col offset={2} span={20}>
                        <Title className="center" level={2}>{t("HealthCare.title2")}</Title>
                        <Text className="center">{t("HealthCare.text1")} <b>{t("HealthCare.text2")}</b> {t("HealthCare.text3")}</Text>
                        <Text className="center">{t("HealthCare.text4")} <b>{t("HealthCare.text5")}</b> {t("HealthCare.text6")}</Text>
                    </Col>
                    <Col span={2}></Col>

                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                <Col span={12}>
                        <Lottie loop play animationData={L1} />
                    </Col>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">Chatbots</Title>
                        <Title level={1}>{t("HealthCare.title3")}</Title>
                        <Text>{t("HealthCare.text7")}</Text>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check1")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check2")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check3")}</Title>
                    </Col>
                    
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("HealthCare.title4")}</Title>
                        <Title level={1}>{t("HealthCare.title5")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check4")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check5")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("HealthCare.check6")}</Title>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L2} />
                    </Col>
                </Row>
                <br />
                <br />
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Lottie loop play animationData={L3} />
                    </Col>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("HealthCare.title6")}</Title>
                        <Title level={1}>{t("HealthCare.title7")}</Title>
                        <Text>{t("HealthCare.text8")}</Text>
                        <br/>
                        <br/>
                        <Text>{t("HealthCare.text9")}</Text>
                    </Col>
                </Row>

                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={24}>
                        <Title className="center" level={1}>{t("HealthCare.title10")}</Title>
                    </Col>
                    <Col span={24} style={{marginTop:'-50px'}}>
                        <Title className="center" level={1}>{t("ipcallgotelohacefacil")}</Title>
                    </Col>
                    <Col offset={8} span={8}>
                        <Button
                            className="btn-naranja center"
                            shape="round"
                            size="large"
                            onClick={() => window.open("/contacto", "_self")}>{t("btn.pedirinfo")}</Button>
                    </Col>
                </Row>
            </div>
        </Content>
    );
};