import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { Content } from "antd/es/layout/layout";
import { useUtils } from 'hooks/useUtils';
//Loties
import Lottie from "react-lottie-player";
import L0 from 'assets/lotties/personasleyendo.json';
import L1 from 'assets/lotties/seleccionaropciones.json';
import L2 from 'assets/lotties/mujerleecelypantalla.json';
import L3 from 'assets/lotties/mujerdesoporte.json';
import L4 from 'assets/lotties/presentaciongrafico.json';
import ML from 'assets/svgs/meli.svg';

//Styles
import styles from "views/style.module.scss";
import '../style.module.scss';

const { Text, Title } = Typography;

export const Caracteristicas = () => {
    const { t } = useUtils();
    const styleIcon = { fontSize: '38px', color: '#818a91' };
    const styleSvg = { fontSize: '39px', color: '#818a91', textAlign: 'center', width: '43px' };
    const rowgutter = [16, 24];
    const rowStyle = { marginTop: '20px', marginBottom: '20px' };
    return (
        <Content>
            <div className={styles.contentSectionWrapper}>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("caracteristicas")}</Title>
                        <Title level={1}>{t("t.solucion")}</Title>
                        <Text>{t("p.tenemoslasolucion")}</Text>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L0} />
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col offset={2} span={20}>
                        <Title className="center" level={2}>{t("t.funcionalidades")}</Title>
                        <Text>{t("p.funcionalidades")}</Text>
                    </Col>
                    <Col span={2}></Col>

                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("elnucleo")}</Title>
                        <Title level={1}>{t("CRM")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("CRM.check0")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("CRM.check1")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("CRM.check2")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("CRM.check3")}</Title>
                        <Title level={4}><CheckOutlined className="naranja icono" />{t("CRM.check4")}</Title>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L1} />
                    </Col>
                </Row>
                <br />
                <br />
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Lottie loop play animationData={L2} />
                    </Col>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("GestionInterna")}</Title>
                        <Title level={1}>{t("t.gestioninterna")}</Title>
                        <Text>{t("p.gestioninterna")}</Text>
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("m.Administracion")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check0")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check1")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check2")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check3")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check4")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("Agentes")}/{t("Operadores")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check5")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check6")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check7")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check8")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check9")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("gestioninterfaces")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check10")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check11")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check12")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check13")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check14")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check15")}</Title>
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("masqueuncrm")}</Title>
                        <Title level={1}>{t("t.softwareomnicanal")}</Title>
                        <Text>{t("p.softwareomnicanal")}</Text>
                        <Row gutter={[48, 48]} style={rowStyle}>
                            <Col span={4}>
                                <i className="fab fa-facebook" style={styleIcon}></i>
                            </Col>
                            <Col span={4}>
                                <i className="fab fa-instagram" style={styleIcon}></i>
                            </Col>
                            <Col span={4}>
                                <img src={ML} alt="MeLi" style={styleSvg} />
                            </Col>
                            <Col span={4}>
                                <i className="fab fa-whatsapp" style={styleIcon}></i>
                            </Col>
                            <Col span={4}>
                                <i className="fab fa-telegram" style={styleIcon}></i>
                            </Col>
                            <Col span={4}>
                                <i className="fas fa-plus" style={styleIcon}></i>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Lottie loop play animationData={L3} />
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("centraltelefonica")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check16")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check17")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check18")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check19")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("audiollamada")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check20")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check21")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check22")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("mensajeria")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check23")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check24")}</Title>
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={12}>
                        <Lottie loop play animationData={L4} />
                    </Col>
                    <Col span={12}>
                        <Title level={3} className="titulo naranja">{t("supervisionycontrol")}</Title>
                        <Title level={1}>{t("t.supervisionycontrol")}</Title>
                        <Text>{t("p.supervisionycontrol")}</Text>
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("planifyestrategias")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check25")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check26")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check27")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("Reportes")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check28")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check29")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check30")}</Title>
                    </Col>
                    <Col span={8}>
                        <Title className="center" level={3}>{t("solucionesdiferen")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check31")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check32")}</Title>
                        <Title level={5}><CheckOutlined className="naranja icono" />{t("caracteristicas.check33")}</Title>
                    </Col>
                </Row>
                <Row gutter={rowgutter} style={rowStyle}>
                    <Col span={24}>
                        <Title className="center" level={1}>{t("t.gestiondeequipos")}</Title>
                    </Col>
                    <Col span={24} style={{marginTop:'-50px'}}>
                        <Title className="center" level={1}>{t("ipcallgotelohacefacil")}</Title>
                    </Col>
                    <Col offset={8} span={8}>
                        <Button
                            className="btn-naranja center"
                            shape="round"
                            size="large"
                            onClick={() => window.open("/contacto", "_self")}>{t("btn.pedirinfo")}</Button>
                    </Col>
                </Row>
            </div>
        </Content>
    );
};