import React from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useUtils } from 'hooks/useUtils';
import Lottie from "react-lottie-player";
import L0 from "assets/lotties/graficocrmdef.json";
import L1 from "assets/lotties/hombreviendograficotablet.json";
import L2 from "assets/lotties/megafonotelefono.json";
import L3 from "assets/lotties/smsflotante.json";
import Voip from 'assets/svgs/voip.svg';
import CRM from 'assets/svgs/crm.svg';
import Omni from 'assets/svgs/omnicanalidad.svg';
import Healthcare from 'assets/svgs/healthcare.svg';
import Calculadora from 'assets/svgs/calculadora.svg';
import Soporte from 'assets/svgs/soporte.svg';
import Business from 'assets/svgs/business.svg';
import { CheckOutlined } from '@ant-design/icons';

//Styles
import styles from "views/style.module.scss";
import '../style.module.scss';

const { Text, Title } = Typography;

export const ContentSection = () => {
  const { t } = useUtils();

  return (
    <Content>
      <div className={styles.contentSectionWrapper}>
        <Row gutter={6}>
          <Col span={12}>
            <Title level={3} className="titulo naranja">{t("CRMDefinitivo")}</Title>
            <Title level={1}>{t("Centraliza.info")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("CRMomnicanal")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("info.noapi")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("info.integracion")}</Title>

            <Button className="btn-naranja" shape="round" size="large">{t("btn.vermas")}</Button>
          </Col>
          <Col span={12}>
            <Lottie loop play animationData={L0} />
          </Col>
        </Row>
        <Row>
          <Col offset={2} span={20}>
            <Title className="center" level={2}>{t("p.ipcall.soluciondefi")}</Title>
          </Col>
          <Col span={2}></Col>

        </Row>
        {/* -- */}
        <Row>
          <Col offset={2} span={20}>
            <Text className="center">{t("p.somos0")} <b>{t("p.somos1")}</b> {t("p.somos2")} <b>{t("p.somos3")}</b> {t("p.somos4")}</Text>
          </Col>
          <Col span={2}></Col>
        </Row>
        {/* -- */}
        <br />
        <Row>
          <Col span={8}>
            <img className="dash-solucion center" src={Voip} alt="voip" />
            <Title className="center" level={3}>Voip</Title>
            <Text className="center" style={{ marginLeft: 2, marginRight: 2 }}>{t("Con su")} <b>{t("p.voip0")}</b> {t("p.voip1")} <b>{t("p.voip2")}</b> {t("p.voip3")}</Text>
          </Col>
          <Col span={8}>
            <img className="dash-solucion center" src={CRM} alt="crm" />
            <Title className="center" level={3}>CRM</Title>
            <Text className="center" style={{ marginLeft: 2, marginRight: 2 }}>{t("Realiza")} <b>{t("p.ges360")}</b> {t("p.ges361")}</Text>
          </Col>
          <Col span={8}>
            <img className="dash-solucion center" src={Omni} alt="omnicanalidad" />
            <Title className="center" level={3}>Omnicanalidad</Title>
            <Text className="center" style={{ marginLeft: 2, marginRight: 2 }}>{t("p.mantienecontacto")} <b>{t("p.multiplescanalaes")}</b> {t("p.demensajes")}</Text>
          </Col>
        </Row>
        <Row>
          <Col offset={8} span={8}>
            <Button
              className="btn-naranja center"
              shape="round"
              size="large"
              onClick={() => window.open("/crm-features", "_self")}>{t("btn.vercaract")}</Button>
          </Col>
        </Row>
        <br />
        <Row gutter={6}>
          <Col span={12}>
            <Lottie loop play animationData={L1} />
          </Col>
          <Col span={12}>
            <Title level={3} className="titulo naranja">{t("t.reportingdinamico")}</Title>
            <Title level={1}>{t("t.controlymonitoreo")}</Title>
            <Text >{t("p.controlymonitoreo0")} <b>{t("p.controlymonitoreo1")}</b> {t("p.controlymonitoreo2")}?</Text>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check0")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check1")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check2")}</Title>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={12}>
            <Title level={3} className="titulo naranja">{t("t.multiplescanales")}</Title>
            <Title level={1}>{t("t.omnicanalidad")}</Title>
            <Text>{t("p.omnicanalidad")}</Text>
            <Title level={4}><CheckOutlined className="naranja icono" />WhatsApp</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />Facebook</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />Instagram</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />Telegram</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />MercadoLibre</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check4")}...</Title>
          </Col>
          <Col span={12}>
            <Lottie loop play animationData={L2} />
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={12}>
            <Lottie loop play animationData={L3} />
          </Col>
          <Col span={12}>
            <Title level={3} className="titulo naranja">{t("t.mensajesauto")}</Title>
            <Title level={1}>Chatbots {t("y")} Workflows</Title>
            <Text>{t("p.integrarbots")}</Text>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check5")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check6")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check7")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check8")}</Title>
          </Col>
        </Row>

        <Row gutter={6}>
          <Col span={12}>
            <Title level={3} className="titulo naranja">{t("t.actoressatisfechos")}</Title>
            <Title level={1}>{t("t.mejoranivel")}</Title>
            <Text>{t("p.actoressatisfechos0")} <b>{t("p.actoressatisfechos1")}</b> {t("p.actoressatisfechos2")}</Text>
          </Col>
          <Col span={12}>
            <Lottie loop play animationData={L0} />
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Title className="center" level={3}>{t("t.unproducto")}</Title>
          <Text>{t("p.unproducto0")} <b>{t("p.unproducto1")}</b> {t("p.unproducto2")}</Text>
        </Row>
        <br />
        <Row>
          <Col span={6}>
            <Card
              hoverable
              style={{ width: '90%', height: '300px', backgroundColor: '#d3bcf6' }}
              onClick={()=>window.open("healthcare","_self")}
            >
              <img className="dash-destinos" src={Healthcare} alt="Healthcare"></img>
              <Title level={2} style={{ color: 'white' }}>{t("card.somos1")}</Title>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              hoverable
              style={{ width: '90%', height: '300px', backgroundColor: '#add5fa' }}
              onClick={()=>window.open("financial","_self")}
            >
              <img className="dash-destinos" src={Calculadora} alt="Calculadora"></img>
              <Title level={2} style={{ color: 'white' }}>{t("card.somos2")}</Title>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              hoverable
              style={{ width: '90%', height: '300px', backgroundColor: '#9bd3ae', alignContent: 'flex-end' }}
              onClick={()=>window.open("bpo","_self")}
            >
              <img className="dash-destinos" src={Business} alt="Business"></img>
              <Title level={2} style={{ color: 'white' }}>{t("card.somos3")}</Title>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              hoverable
              style={{ width: '90%', height: '300px', backgroundColor: '#d3bcf6' }}
              onClick={()=>window.open("pymes","_self")}
            >
              <img className="dash-destinos" src={Soporte} alt="Soporte"></img>
              <Title level={2} style={{ color: 'white' }}>{t("card.somos4")}</Title>
            </Card>
          </Col>
        </Row>

        <br />

        <Row gutter={24}>
          <Col span={8}>
            <div style={{ alignContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
              <Title level={3} className="titulo naranja">{t("t.xqelegirnos")}</Title>
              <Title level={1}>10 {t("Razones")}</Title>
              <Text>{t("p.ipcallofrece")}</Text>
              <br />
              <br />
              <Row gutter={24}>
                <Button className="btn-naranja center" shape="round" size="large">{t("btn.vermas")}</Button>
              </Row>
            </div>
          </Col>
          <Col span={16}>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check9")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check10")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check11")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check12")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check13")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check14")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check15")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check16")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check17")}</Title>
            <Title level={4}><CheckOutlined className="naranja icono" />{t("check18")}</Title>
          </Col>
        </Row>
      </div>
    </Content>
  );
};


