import { useTranslation } from 'react-i18next';

export function useUtils(props){
    const {t} = useTranslation()
  
    return {
        t:word => t(word), 
    }
}
export function Refresh(props){
    const {i18n} = useTranslation()
  
    return {
        i18n:word => i18n.changeLanguage(word), 
    }
}