import { createContext, useContext } from "react"
import { RenderHeader } from "./Header";
import { RenderFooter } from "./Footer";
import { RenderRoutes } from './RenderNavigation'
import { useAuth0 } from "@auth0/auth0-react";


const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {

  const {
    user,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();


  const logoutWithRedirect = () => {
    localStorage.removeItem("AccessToken")
    logout();
  }

  return (

    <AuthContext.Provider value={{ user, isAuthenticated, loginWithRedirect, logoutWithRedirect, getAccessTokenSilently }}>
      <>
        <RenderHeader />
        <RenderRoutes />
        <RenderFooter />
      </>

    </AuthContext.Provider>

  )

}