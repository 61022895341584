import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { App as AppAntD } from 'antd';
import './App.css';
import { AuthWrapper } from './Layout';
import './lang'
import { IntlProvider } from 'react-intl';
import AppLocale from 'lang/intl';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import { THEME_CONFIG } from 'config/UserConfig';
function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    console.log(THEME_CONFIG.locale)
    return <Loading cover="content"/>;

  }
  return (
    <div className="App">
      <BrowserRouter>
        <IntlProvider locale={AppLocale[THEME_CONFIG.locale].locale} messages={AppLocale[THEME_CONFIG.locale].messages}>
          <AppAntD>
            <AuthWrapper />
          </AppAntD>
        </IntlProvider>
      </BrowserRouter>      
    </div>
  );
}

export default App;