import 'isomorphic-fetch';

export const config ={
    URL_API: 'http://apps.ipcall.com.ar/api',
}


 const api = {
    user: {
        async profile() {
            const response = await fetch(config.URL_API + '/profile', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${localStorage.getItem("AccessToken")}`
                }
            });
            const data = await response.json();
            return data;
        },
        async setProfile(form) {  
            const response = await fetch(config.URL_API + '/profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${localStorage.getItem("AccessToken")}`
                },
                body: JSON.stringify(form)
            });
            const data = await response.json();
            return data;
        },
        async setProfileLocale(user) {  
            const response = await fetch(config.URL_API + '/profile/locale', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${localStorage.getItem("AccessToken")}`
                },
                body: JSON.stringify(user)
            });
            const data = await response.json();
            return data;
        },
        async getProfileLocale() {  
            const response = await fetch(config.URL_API + '/getAuthenticated', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${localStorage.getItem("AccessToken")}`
                }
            });
            const data = await response.json();
            return data;
        },
    },
 }
  
export default api